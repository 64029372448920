<template>
  <section class="login">
    <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
                <div class="auth-form-light text-left p-5 my_auth">
                  <div class="brand-logo">
                    <img class="logo_image" src="@/assets/images/import/logo-mini.png">
                  </div>
                  <h4>Bienvenue sur Mon Echappée Belle!</h4>
                  <h6 class="font-weight-light">Connectez-vous pour continuer</h6>
                  <!-- formulaire d'authentification -->
                  <form class="pt-3" @submit.prevent="submitFormLogin">
                    <b-alert show variant="warning" v-if="error"><a href="javascript:void(0);" class="alert-link">Mot de passe ou identifiant incorrect. Veuillez réessayer.</a></b-alert>
                    <div class="form-group">
                      <input type="text" class="form-control form-control-lg" v-model="user_name" placeholder="Nom d'utilisateur">
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control form-control-lg" v-model="user_password" placeholder="Mot de passe">
                    </div>
                    <div class="mt-3">
                      <button type="submit " class="btn btn-block btn-lg font-weight-medium auth-form-btn btn-sign-up">S'IDENTIFIER</button>
                    </div>
                    <div class="my-2 d-flex justify-content-between align-items-center">
                      <div class="form-check">
                        <a href="javascript:void(0);" class="auth-link text-black" @click="signin()">S'inscrire</a>
                      </div>
                        <label class="form-check-label text-muted pointer" @click="forgotPassword()">
                          Mot de passe oublié?
                        </label>
                    </div>
                    
                  </form>
                  <!-- Fin formulaire -->
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>

</template>

<script>
import axios from 'axios';
import pageLoader from '../components/pageLoader.vue';

export default {
  name: 'login',
  components:{
    pageLoader
  },
  data(){return {
      loadershow:false,
      error:false,
      user_name:'',
      user_password:'',
  }},
  methods:{
      submitFormLogin(){
        this.loadershow = true
          const formData = {
              username:this.user_name,
              password:this.user_password,
          }
          // delete axios.defaults.headers.common["X-AUTH-TOKEN"]
          axios.post('login',formData, {
            // withCredentials:true,
          })
            .then(
                res => {
                    axios.get('myinfos',{headers:{
                      'X-AUTH-TOKEN':res.data.token} })
                    .then(resGet => {
                        //Enregistrer l'utilisateur dans authUser
                        this.$store.dispatch('login',{resmyinfo:resGet ,reslogin:res})
                    })
                    .catch(errGet => {
                      console.log({errGet});
                        // this.$snotify.warning(errGet.response);

                    })
                }
            )
            .catch(
                err => {
                  console.log({err});
                  if (err.message == "Network Error") {
                     axios.post('login',formData)
                      .then(
                        reser => {
                            axios.get('myinfos',{headers:{
                              'X-AUTH-TOKEN':reser.data.token} })
                            .then(resGeter => {
                                //Enregistrer l'utilisateur dans authUser
                                this.$store.dispatch('login',{resmyinfo:resGeter ,reslogin:reser})
                            })
                            .catch(errGet => {
                              this.loadershow = false
                              console.log({errGet});
                                // this.$snotify.warning(errGet.response);

                            })
                        }
                      )
                      .catch(errLog => {
                        this.loadershow = false
                        this.$snotify.warning("Network Error");
                        console.log(errLog);
                      })
                  }
                  if (err.response.status) {
                      this.loadershow = false
                      this.error = true
                  }                
                }
            )
      },
      signin() {
        this.$router.push({
          name:"signin"
        })
      },
      forgotPassword() {
        this.$router.push ({
          name: "forgotPassword"
        })
      }
  }
}
</script>
<style scoped>
.brand-logo{
    text-align: center;
}
.my_auth{
  border-radius: 10px;
  border: solid 5px #DCCE85;
  box-shadow: 9px 7px 53px -29px rgba(0,0,0,0.65);
}
.btn-sign-up{
  background-color: #474747;
  color: whitesmoke;
}
.btn-sign-up:hover{
  box-shadow: 0px 1px 10px 0px rgba(92,92,16,0.75);
}
</style>